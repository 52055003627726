import React, { useState, useEffect, useRef } from "react"
import turnitinLogo from '../../assets/images/turnitin.jpg'
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Input,
    Label,
    Modal,
    Table,
} from "reactstrap";
import * as helper from '../../utils/helper'
import { Grading, Delete, Edit, Visibility, Mail } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import CustomModal from "../Common/CustomModal"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js"; 
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import * as Session from "../../helpers/session_helper"


import Axios from "../../helpers/axios_helper"
import * as SHelper from "../../helpers/session_helper"
import { useHistory } from "react-router-dom"
import { toast, Slide } from 'react-toastify/dist/react-toastify';

const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

const Model = (props) => {
    const history = useHistory()
    const [courseAssignments, setcourseAssignments] = useState([]);
    const [updateData, setUpdateData] = useState(false)
    const currentSelectedAssignment = useRef(0);
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [rubricDD, setRubricDD] = useState([]);

    const [overviewState, setOverviewState] = useState(EditorState.createEmpty());
    const [overviewContent, setOverviewContent] = useState();

    const [courseModuleId, setCourseModuleId] = useState(0);
    const [module, setModule] = useState(false);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(false);

    const [showCreateNewUI, setShowCreateNewUI] = useState(false);
    const [showcourseAssignmentSubmissions, setShowCourseAssignmentSubmissions] = useState(false);
    const [showRubricGrading, setShowRubricGrading] = useState(false);
    const [rubricDetails, setRubricDetails] = useState(false);
    const [rubricGrade, setRubricGrade] = useState([]);
    const [totalRubricGrade, setTotalRubricGrade] = useState(0);
    const [instructorRemarks, setInstructorRemarks] = useState('');

    const [courseAssignmentId, setCourseAssignmentId] = useState(-1);
    const [courseAssignmentIdx, setCourseAssignmentIdx] = useState(-1);
    const [courseAssignmentName, setCourseAssignmentName] = useState('');
    const [courseAssignmentPoint, setCourseAssignmentPoint] = useState('');
    const [courseAssignmentMaxRetake, setCourseAssignmentMaxRetake] = useState('');
    const [courseAssignmentDescription, setCourseAssignmentDescription] = useState('');
    const [courseAssignmentStartDate, setCourseAssignmentStartDate] = useState(null);
    const [courseAssignmentEndDate, setCourseAssignmentEndDate] = useState(null);
    const [courseAssignmentRubricId, setCourseAssignmentRubricId] = useState(null);
    
    const [modal_Assignment, setModal_Assignment] = useState(null);

    const [sessionUser, setSessionUser] = useState(false);
    const [disableSubmission, setDisableSubmission] = useState(false);
    const [submittedFiles, setSubmittedFiles] = useState([]);

    const [courseAssignmentIsPractice, setCourseAssignmentIsPractice] = useState(null);    
    const [courseAssignmentIsExternal, setCourseAssignmentIsExternal] = useState(null);  

    const selectedItem = useRef(0)
    const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);

    const [announcement_mail_modal, setAnnouncement_mail_modal] = useState(false);

    const handleOverviewChange = (newEditorState) => {
        setOverviewState(newEditorState);
        setOverviewContent(stateToHTML(newEditorState.getCurrentContent()));
    };

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            var updateData = courseAssignments;
            updateData.forEach(element => {
                element.courseId = props.courseId;
                if (element.courseAssignmentIsActive) {
                    element.courseAssignmentIsActive = element.courseAssignmentIsActive == 1 ? true : false;
                }
            });

            const jsonData = {
                assignments: updateData
            }

            await Axios.post("/courseAssignment/bulk", jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Course Modules Created/Updated successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });

                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    cancelcourseAssignmentEdit();
                    fetchDataFromAPI();
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })

        }
        window.hLoader();
    }

    const sendAnnouncementMail = async (modalResponse) => {
        if(!modalResponse) return;
        await Axios.post(`/courseAssignment/sendMail/${selectedItem.current}`)
        .then((response) => {
            if (response.data.status==200) {
                toast.success(`Mail Send successfully !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
            else {
                toast.error(`Mail Send failed !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        });
        setAnnouncement_mail_modal(false);
    }

    const fileChangeEvent = (event) => {
        setFiles([...event.target.files]);
    }

    const fetchSubmittedFiles = async(assignmentId,canSubmitFiles=true) =>{
        setSubmittedFiles([]);
        if(sessionUser && sessionUser.role_code=="444"){
            setDisableSubmission(true);
            currentSelectedAssignment.current=assignmentId;
            const data = Session.getUser();
            await Axios.get(`/file/uploadWithDB/assignmentSubmissions&${props.courseId}&${props.moduleId}&${assignmentId}&${data.userDetailId}`)
            .then(async (result)=>{
                if(result.data.status=200){
                    setSubmittedFiles(result.data.data);
                    if(courseAssignments && courseAssignments.length>0){
                        if(fileInputRef.current && fileInputRef.current.value){
                            fileInputRef.current.value = null;
                        }
                        let selectedCourseModule = courseAssignments.filter(x=>x.moduleId==props.moduleId && x.courseId==props.courseId)[0];
                        let usedSubmission = getMaxValue(result.data.data,"fileSequence");
                        if(selectedCourseModule.courseAssignmentMaxRetake>usedSubmission && canSubmitFiles){
                            setDisableSubmission(false);
                            // toast.error("You already used maximum re-submission quota !", {
                            //     position: toast.POSITION.TOP_RIGHT,
                            //     autoClose: 3000,
                            // });
                        }
                    }
                }
            })
        }
        else{
            setDisableSubmission(true);
            await Axios.get(`/file/uploadWithDB/all/assignmentSubmissions&${props.courseId}&${props.moduleId}&${assignmentId}`)
            .then(async (result)=>{
                if(result.data.status=200){
                    setSubmittedFiles(result.data.data);
                    if(courseAssignments && courseAssignments.length>0){
                        if(fileInputRef.current && fileInputRef.current.value){
                            fileInputRef.current.value = null;
                        }
                        let selectedCourseModule = courseAssignments.filter(x=>x.moduleId==props.moduleId && x.courseId==props.courseId)[0];
                        let usedSubmission = getMaxValue(result.data.data,"fileSequence");
                        if(selectedCourseModule.courseAssignmentMaxRetake<=usedSubmission){
                            setDisableSubmission(true);
                            // toast.error("You already used maximum re-submission quota !", {
                            //     position: toast.POSITION.TOP_RIGHT,
                            //     autoClose: 3000,
                            // });
                        }
                    }
                }
            })
        }
        
    }

    const getMaxValue = (arr, field) => {
        if(arr)
        return arr.reduce((max, obj) => {
            return (obj[field] > max) ? obj[field] : max;
        }, 0);
        else return 0;
    };

    const onchangeRubricRangeHandler = (id, points, maxValue) => {
        // console.log(`Slider value changed for id: ${id}, new points: ${points}`);
        setRubricGrade(prevRubricGrade => ({
        ...prevRubricGrade,
        [id]: points,
        }));
    }

    const calculateTotalWeightage = () => {
        if(rubricDetails && rubricDetails.rubricCriteria.length>0){
            let totalWeightage = 0;
            let criteriaCount = 0;
            
            for (const id in rubricGrade) {
                const points = rubricGrade[id];
                const maxValue = rubricDetails.rubricCriteria.filter(x=>x.id==id)[0].maxPoints;
                if (maxValue > 0) { // To avoid division by zero
                    totalWeightage += (points / maxValue) * 100;
                    criteriaCount++;
                }
            }
            
            return criteriaCount > 0 ? (totalWeightage / criteriaCount).toFixed(2) : 0;
        }
        
    };
    
    const [lastOpenedRubricData,setLastOpenedRubricData] = useState(false);
    const rubricGradingHandler = async (data) =>{
        // selectedSubmissionId
        const userDetails = SHelper.getUser();
        window.sLoader();
        if(selectedSubmissionId){
            setLastOpenedRubricData({
                assignmentId:selectedSubmissionId,
                academicProfileId:data['user.userDetail.academicProfile.id']

            });
            await Axios.get(`/courseAssignment/submission/${selectedSubmissionId}&${data['user.userDetail.academicProfile.id']}`)
            .then(async course=>{
                if(course.data.status==200){
                    course = course.data.data;
                    await Axios.get(`/rubric/details/byAssignment/${selectedSubmissionId}`)
                    .then((response) => {
                        if (response.data.status==200) {
                            if(!response.data.data.rubricId){
                                toast.error(`No Rubric Grading Found !`, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                            }
                            else{
                                setRubricDetails(response.data.data.rubric);
                                setInstructorRemarks(course.instructorRemarks);
                                let previousGrading = {};
                                if(course.rubricResult) previousGrading = JSON.parse(course.rubricResult);
                                if(response.data.data.rubric.rubricCriteria)
                                {
                                    const initialRubricGrade = {};
                                        response.data.data.rubric.rubricCriteria.forEach(criteria => {
                                        initialRubricGrade[criteria.id] = previousGrading[criteria.id]??0;
                                    });
                                    setRubricGrade(initialRubricGrade);
                                }
                                setShowRubricGrading(true);  
                            }
                        }
                        else{

                        }
                    })
                    .catch(error=>{
                        console.log(error);
                        toast.error(`Error Occured`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
                }
                else{
                    toast.error(`Submission not found !`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    return;
                }
            })
            
        } 
        window.hLoader();
    }
    
    const submitRubricGrading = async () =>{
        const body = {
            rubricResult:JSON.stringify(rubricGrade),
            rubricWeight:calculateTotalWeightage(),
            instructorRemarks:instructorRemarks
        }
        await Axios.patch(`/rubric/assignment/grade/${selectedSubmissionId}&${lastOpenedRubricData.academicProfileId}`,body)
        .then((response) => {
            if (response.data.status==200) {
                toast.success(`Rubric Grading Updated Successsfully!`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                setShowRubricGrading(false);
            }
            else{
                toast.error(`Rubric Grading not Updated !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        })
        .catch(error=>{

        })
    }

    const handleSubmission = async (event, errors, values) => {
        window.sLoader();
        if (!props.courseId) {
            toast.error(`Course is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else if (!props.moduleId) {
            toast.error(`Module is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        else {
            
            let errorFound = false;
            let formData = new FormData();

            //#region payment File Upload
            if(files.length>0){
                const submissionFile = files[0];
                
                const jsonData = {
                    courseId: props.courseId,
                    moduleId: props.moduleId,
                    userDetailId: sessionUser.userDetailId,
                    courseAssignmentId: currentSelectedAssignment.current,
                    // isExternal: courseAssignmentIsExternal,
                    // isPractice: courseAssignmentIsPractice,
                }
                const url=`/courseAssignment/submission/${props.courseId}&${props.moduleId}&${currentSelectedAssignment.current}&${sessionUser.userDetailId}`;
                await Axios.post(url, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {

                        await Axios.get(`/file/uploadWithDB/assignmentSubmissions&${props.courseId}&${props.moduleId}&${currentSelectedAssignment.current}&${sessionUser.userDetailId}`)
                        .then(async (result)=>{
                            await Axios.delete(`/file/uploadWithDB/assignmentSubmissions&${props.courseId}&${props.moduleId}&${currentSelectedAssignment.current}&${sessionUser.userDetailId}`)
                            .then(async () =>{
                                let usedSubmission = getMaxValue(result.data.data,"fileSequence");

                                let selectedCourseModule = courseAssignments.filter(x=>x.moduleId==props.moduleId && x.courseId==props.courseId)[0];
                                if(selectedCourseModule.courseAssignmentMaxRetake<=usedSubmission){
                                    setDisableSubmission(true);
                                    // toast.error("You already used maximum re-submission quota !", {
                                    //     position: toast.POSITION.TOP_RIGHT,
                                    //     autoClose: 3000,
                                    // });
                                    return ;
                                }

                                formData = new FormData();
                                formData.append('file', submissionFile);
                                formData.append('body', JSON.stringify([{
                                    fileName : submissionFile.name,
                                    fileSequence : usedSubmission+1,
                                    fileNameWithExt : submissionFile.name
                                }]));
                                await Axios.post(`/file/turnitin/uploadWithDB/${response.data.submissionId}&${currentSelectedAssignment.current}/assignmentSubmissions&${props.courseId}&${props.moduleId}&${currentSelectedAssignment.current}&${sessionUser.userDetailId}`, formData, {
                                })
                                .then(async (fileUploadResult)=>{
                                    if(fileUploadResult.status){
                                        await fetchSubmittedFiles(currentSelectedAssignment.current);
                                        toast.success("Submitted Successfully !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 3000,
                                        });
                                    }
                                    else{
                                        toast.error(fileUploadResult.message, {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 3000,
                                        });
                                    }
                                
                                })
                                .catch(function (error) {
                                    errorFound = true;
                                    toast.error("Submission File Uploaded Failed !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                });
                            })
                            
                        })
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
                
                
            }
            
            //#endregion

        }
        window.hLoader();
    }

    const addcourseAssignment = () => {
        if (!courseAssignmentName) {
            toast.error(`Name is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else if (!courseAssignmentMaxRetake) {
            toast.error(`Retake is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else if (!courseAssignmentStartDate) {
            toast.error(`Start Time is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else if (!courseAssignmentEndDate) {
            toast.error(`End Time is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else if (!courseAssignmentRubricId) {
            toast.error(`Rubric is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else if (!overviewContent) {
            toast.error(`Description is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        else{
            if (courseAssignmentIdx > -1) {
                courseAssignments[courseAssignmentIdx].id = courseAssignmentId;
                courseAssignments[courseAssignmentIdx].courseAssignmentName = courseAssignmentName;
                courseAssignments[courseAssignmentIdx].moduleId = props.moduleId;
                courseAssignments[courseAssignmentIdx].courseId = props.courseId;
                courseAssignments[courseAssignmentIdx].courseAssignmentPoint = courseAssignmentPoint;
                courseAssignments[courseAssignmentIdx].courseAssignmentMaxRetake = courseAssignmentMaxRetake;
                courseAssignments[courseAssignmentIdx].courseAssignmentDescription = overviewContent;
                courseAssignments[courseAssignmentIdx].courseAssignmentStartDate = courseAssignmentStartDate;
                courseAssignments[courseAssignmentIdx].courseAssignmentEndDate = courseAssignmentEndDate;
                courseAssignments[courseAssignmentIdx].rubricId = courseAssignmentRubricId;
                courseAssignments[courseAssignmentIdx].courseAssignmentIsPractice = courseAssignmentIsPractice;
                courseAssignments[courseAssignmentIdx].courseAssignmentIsExternal = courseAssignmentIsExternal;
            }
            else {
                courseAssignments.push({
                    courseAssignmentName: courseAssignmentName,
                    courseAssignmentPoint: 0,//courseAssignmentPoint,
                    moduleId : props.moduleId,
                    courseId : props.courseId,
                    courseAssignmentDescription: overviewContent,
                    courseAssignmentStartDate: courseAssignmentStartDate,
                    courseAssignmentEndDate: courseAssignmentEndDate,
                    courseAssignmentMaxRetake: courseAssignmentMaxRetake,
                    courseAssignmentPoint:courseAssignmentPoint,
                    rubricId: courseAssignmentRubricId,
                    courseAssignmentIsPractice : courseAssignmentIsPractice,
                    courseAssignmentIsExternal : courseAssignmentIsExternal
                });
            }
            setcourseAssignments(courseAssignments);
            handleSubmit();
        }
    }

    const cancelcourseAssignmentEdit = () => {
        setCourseAssignmentIdx(-1);
        setCourseAssignmentId(-1);
        setCourseAssignmentName('');
        setCourseModuleId(0);
        setCourseAssignmentPoint(0)
        setCourseAssignmentDescription('')
        setCourseAssignmentMaxRetake('');
        setCourseAssignmentRubricId(null);
        setOverviewState(EditorState.createEmpty());
        setOverviewContent('');
        setCourseAssignmentStartDate(null);
        setCourseAssignmentEndDate(null);
        setShowCreateNewUI(false);
    }

    const editcourseAssignment = async (id, idx) => {
        window.sLoader();
        await Axios.get("/courseAssignment/id/"+id)
        .then((response) => {
            if (response.data.status==200) {
                setShowCreateNewUI(true);
                setCourseAssignmentId(id);
                setCourseAssignmentIdx(idx);
                setCourseModuleId(courseAssignments[idx].moduleId);
                setCourseAssignmentName(courseAssignments[idx].courseAssignmentName);
                setCourseAssignmentPoint(courseAssignments[idx].courseAssignmentPoint);
                setCourseAssignmentMaxRetake(courseAssignments[idx].courseAssignmentMaxRetake);
                setCourseAssignmentRubricId(courseAssignments[idx].rubricId);
                setCourseAssignmentDescription(courseAssignments[idx].courseAssignmentDescription);
                setCourseAssignmentIsExternal(courseAssignments[idx].courseAssignmentIsExternal==1);
                setCourseAssignmentIsPractice(courseAssignments[idx].courseAssignmentIsPractice==1);
                const courseAssignmentDescription = response.data.data.courseAssignmentDescription;
                setOverviewContent(courseAssignmentDescription)
                const courseOverview = EditorState.createWithContent(stateFromHTML(courseAssignmentDescription));
                setOverviewState(courseOverview)
                setCourseAssignmentStartDate(courseAssignments[idx].courseAssignmentStartDate);
                setCourseAssignmentEndDate(courseAssignments[idx].courseAssignmentEndDate);
            }
        });
        window.hLoader();
    }

    const deletecourseAssignment = async () => {
        window.sLoader();
        const url = `/courseAssignment/id/${selectedItem.current}`;
        await Axios.delete(url)
        .then(function (response) {
            if (response && response.data?.status==200) {
            toast.success(response.data.message, {
                position: toast.POSITION.RIGHT,
                autoClose: 3000,
                transition: Slide,
            });
            fetchDataFromAPI();
            }
            else {
            toast.error(response.statusText ?? "Data fetching failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                transition: Slide,
            });
            }
        })
        .catch(function (error) {
            // navigate('/');
        });
        window.hLoader();
    }

    const courseAssignmentSubmissions = async (item, idx) => {
        // await Axios.get("/courseAssignment/submissions/"+id)
        // .then((response) => {
        //     if (response.data.status==200) {
        //         setModule(response.data.data);
        //         setModal_Assignment(true);
        //     }
        // });
        let canSubmitFiles = true;
        const data =checkDateRange(item.courseAssignmentStartDate,item.courseAssignmentEndDate);
        if(data[3]!=true){
            canSubmitFiles = false
        }
        setSelectedSubmissionId(item.id);
        fetchSubmittedFiles(item.id,canSubmitFiles);
        setShowCourseAssignmentSubmissions(true);
    }

    const getSubmissionId = (data) =>{
        try {
            var turnitinSubmissionId = JSON.parse(data.fileDetails).turnitinSubmissionId;
            navigator.clipboard.writeText(turnitinSubmissionId).then(() => {
                toast.success('Turnitin SubmissionId copied to clipboard !', {
                    position: toast.POSITION.RIGHT,
                    autoClose: 1000,
                    transition: Slide,
                });
              }).catch(err => {
                toast.error('Failed to copy turnitin submissionId !', {
                    position: toast.POSITION.RIGHT,
                    autoClose: 1000,
                    transition: Slide,
                });
              });
        } catch (error) {
            return false;
        }
    }

    const getCloudViewUrl = async (data) =>{
        try {
            window.sLoader();
            if(data.fileDetails){
                const submissionId=JSON.parse(data.fileDetails).turnitinSubmissionId;
                await Axios.get(`/courseAssignment/submission/cloudViewUrl/${submissionId}`)
                .then((response) => {
                    window.hLoader();
                    if (response.data && response.data.status==200) {
                        window.open(response.data.viewer_url, '_blank');
                    }
                    else {
                        toast.error("Unable to view result !", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                });
            }
            else{
                window.hLoader();
                toast.error("Unable to view result !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            window.hLoader();
            toast.error("Unable to view result !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    }

    const showAssignmentDescription = async (id)=>{
        await Axios.get("/courseAssignment/id/"+id)
        .then((response) => {
            if (response.data.status==200) {
                setModule(response.data.data);
                setModal_Assignment(true);
            }
        });
    }

    function checkDateRange(fromDate, toDate) {
        const currentDate = new Date();
        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);
    
        if (currentDate >= startDate && currentDate <= endDate) {
            return ["badge badge-soft-success font-size-12","Ongoing",true, true]; //'Current date is within the range'
        } else if (currentDate > startDate) {
            return ["badge badge-soft-danger font-size-12","Completed",true, false]; //'Current date is before the range'
        } else {
            return ["badge badge-soft-warning font-size-12","Upcoming", false, false]; //'Current date is after the range'
        }
    }

    const fetchDataFromAPI = async ()=>{
        setcourseAssignments([])
        await Axios.get(`/courseAssignment/list/${props.courseId}&${props.moduleId}`)
                .then((response) => {
                    if (response.data.recordsTotal) {
                        setcourseAssignments(response.data.data);
                    }
                });
    }

    useEffect(async () => {
        const data = Session.getUser();
        setSessionUser(data);

        if(props.moduleId>0){
            setCourseModuleId(props.moduleId);
        }
        if (props.courseId>0 && props.moduleId>0) {
            fetchDataFromAPI();
        }
    }, [props.courseId,props.moduleId])

    
    useEffect(async () => {
        await Axios.get(`/rubric/dropdown`)
        .then((response) => {
            if (response.data.status==200) {
                setRubricDD(response.data.data);
            }
        });
    }, [])


    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                    <Card>
                        <CardTitle style={{ fontSize: "18px", textAlign: "center", opacity:"0.7" }}>Assignment</CardTitle>
                        <CardBody style={{ paddingTop: "0.5rem" }}>
                            <AvForm className="needs-validation" onSubmit={handleSubmit}>
                            {SHelper.userHasEditPermission() && showCreateNewUI &&
                            <>
                                <Col md="12">
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Name</Label>
                                                <AvField
                                                    name="courseAssignmentName"
                                                    value={courseAssignmentName}
                                                    onChange={(e) => { setCourseAssignmentName(e.target.value); }}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                       <Col md="6">
                                            <div className="mb-3">
                                                <Label>Can Retake</Label>
                                                <AvField
                                                    name="courseAssignmentMaxRetake"
                                                    value={courseAssignmentMaxRetake}
                                                    onChange={(e) => { setCourseAssignmentMaxRetake(e.target.value); }}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Start Datetime</Label>
                                                <Flatpickr
                                                    name={"courseAssignmentStartDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value={courseAssignmentStartDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                        // altInput: true,
                                                        // altFormat: 'F j, Y h:i K',
                                                        // altInputClass: 'flatpickr-alt-input',
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseAssignmentStartDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>End Datetime</Label>
                                                <Flatpickr
                                                    name={"courseAssignmentEndDate"}
                                                    className="form-control d-block"
                                                    // placeholder="dd M, yyyy"
                                                    value = {courseAssignmentEndDate}
                                                    options={{
                                                        dateFormat: 'Y-m-d h:i K',
                                                        timeFormat: 'h:i K',
                                                        enableTime: true,
                                                        time_24hr: false,
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setCourseAssignmentEndDate(dateStr);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                         <Col md="6">
                                            <div className="mb-3">
                                                <Label>Point</Label>
                                                <AvField
                                                    name="courseTypeSequence"
                                                    value={courseAssignmentPoint}
                                                    readOnly={true}
                                                    type="number"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Rubric</Label>
                                                <Select
                                                    style={{zIndex:999}}
                                                    value={rubricDD ? rubricDD.filter(x => x.value == courseAssignmentRubricId)[0] : null}
                                                    isMulti={false}
                                                    onChange={(data) => {
                                                        setCourseAssignmentRubricId(data.value);
                                                        setCourseAssignmentPoint(rubricDD.filter(x => x.value == data.value)[0].totalPoints);
                                                    }}
                                                    options={rubricDD}
                                                    classNamePrefix="select2-selection"
                                                    />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Is External</Label>
                                                <Select
                                                    value={options.find(option => option.value == courseAssignmentIsExternal)}
                                                    isMulti={false}
                                                    onChange={(data) => {
                                                        setCourseAssignmentIsExternal(data.value);
                                                    }}
                                                    options={options}
                                                    classNamePrefix="select2-selection"
                                                    />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label>Is Practice</Label>
                                                <Select
                                                    value={options.find(option => option.value == courseAssignmentIsPractice)}
                                                    isMulti={false}
                                                    onChange={(data) => {
                                                        setCourseAssignmentIsPractice(data.value);
                                                    }}
                                                    options={options}
                                                    classNamePrefix="select2-selection"
                                                    />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label>Description</Label>
                                                {/* <AvField
                                                    name="courseTypeDescription"
                                                    value={courseAssignmentDescription}
                                                    onChange={(e) => { setCourseAssignmentDescription(e.target.value); }}
                                                    type="textarea"
                                                    className="form-control"
                                                /> */}
                                                <Editor
                                                    editorState={overviewState}
                                                    onEditorStateChange={handleOverviewChange}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <div className="actions clearfix">
                                    <Button
                                        style={{ float: "right", marginLeft: "10px" }}
                                        type="button"
                                        color="primary"
                                        onClick={addcourseAssignment}
                                    >{courseAssignmentIdx > -1 ? "Update" : "Add"}</Button>
                                    {courseAssignmentIdx > -1 &&
                                        <Button
                                            style={{ float: "right" }}
                                            type="button"
                                            color="danger"
                                            onClick={cancelcourseAssignmentEdit}
                                        >Cancel Edit</Button>}
                                        {courseAssignmentIdx == -1 && showCreateNewUI &&
                                        <div className="actions clearfix">
                                            <Button
                                                onClick={()=>setShowCreateNewUI(false)}
                                                style={{ float: "left" }}
                                                type="button"
                                                color="danger"
                                                >Cancel</Button>
                                        </div>}
                                </div>
                                </>}
                                {SHelper.userHasEditPermission() && !showCreateNewUI &&
                                    <div className="actions clearfix">
                                        <Button
                                            onClick={()=>setShowCreateNewUI(true)}
                                            style={{ float: "left" }}
                                            type="button"
                                            color="primary"
                                            >Create New Assignment</Button>
                                    </div>}
                                    <br/>
                                {/* <CardTitle typeof="h4" >Assignments List</CardTitle> */}
                                {!showCreateNewUI &&
                                <Col md={12}>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Retake</th>
                                                    {/* <th>Points</th> */}
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Details</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {courseAssignments.map((item, i) => {
                                                    let dateStatus = checkDateRange(item.courseAssignmentStartDate,item.courseAssignmentEndDate);
                                                    return (
                                                        <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{item.courseAssignmentName}</td>
                                                            <td>{item.courseAssignmentMaxRetake??0}</td>
                                                            {/* <td>{item.courseAssignmentPoint}</td> */}
                                                            <td>{item.courseAssignmentStartDateView}</td>
                                                            <td>{item.courseAssignmentEndDateView}</td>
                                                            <td>
                                                                <Box style={{cursor:"pointer"}} sx={{ display: courseAssignmentIdx === i ? 'none' : 'flex' }} onClick={() => { showAssignmentDescription(item.id) }}>
                                                                    <Visibility /> 
                                                                    {" "}
                                                                    <Tooltip arrow placement="left" title={ 'See Details'}>
                                                                        <IconButton>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </td>
                                                            <td><span className={dateStatus[0]}>{dateStatus[1]}</span></td>
                                                            <td>
                                                                <Box sx={{ display: courseAssignmentIdx === i ? 'none' : 'flex' }}>
                                                                    
                                                                    {SHelper.userHasEditPermission() && <>
                                                                        <Tooltip arrow placement="left" title="Edit">
                                                                            <IconButton onClick={() => { editcourseAssignment(item.id, i) }}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="left" title="Delete">
                                                                            <IconButton style={{ color: "red" }} onClick={() => { setUpdate_status_modal_center(true); selectedItem.current=item.id; }}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip arrow placement="top" title="Send Mail to All">
                                                                            <IconButton onClick={() => { selectedItem.current=item.id; setAnnouncement_mail_modal(true) }}>
                                                                                <Mail />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        </>}
                                                                        {(dateStatus[2] || SHelper.userHasEditPermission()) &&
                                                                        <Tooltip arrow placement="left" title="Submissions">
                                                                            <IconButton style={{ color: "blue" }} onClick={() => { courseAssignmentSubmissions(item, i) }}>
                                                                                <Grading />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                </Box>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>}
                                {/* {courseAssignments.length > 0 &&
                                    <div className="actions clearfix">
                                        <Button
                                            style={{ float: "right", marginLeft: "10px" }}
                                            type="submit"
                                            color="primary"
                                        >Update</Button>
                                    </div>} */}
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }

        <Modal
            size="xl"
            isOpen={modal_Assignment}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Assignment Description</h5>
              <button
                type="button"
                onClick={()=>{setModal_Assignment(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><div dangerouslySetInnerHTML={{ __html: module.courseAssignmentDescription }} style={{padding:"10px"}}></div></p>
            </div>
          </Modal>


          <Modal
            size="xl"
            isOpen={showcourseAssignmentSubmissions}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Assignment Submission</h5>
              <button
                type="button"
                onClick={()=>{setShowCourseAssignmentSubmissions(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                {!disableSubmission && sessionUser.role_code=="444" &&
                <Row>
                    <div className="mb-3 col-md-9">
                        <div className="input-group">
                            <Input type="file" className="form-control" id="inputGroupFile02" ref={fileInputRef} onChange={fileChangeEvent} />
                            <Label style={{ marginTop: "0" }} className="input-group-text" for="inputGroupFile02">Attach Your Assesment File</Label>
                            {/* {paymentFileSrc && <button type="button" className="btn btn-primary" value="Download" onClick={() => CHelper.downloadFile(paymentFileSrc, "payment")}><i className="fa fa-download"></i> Download Attached File</button>} */}
                        </div>
                    </div>

                    <div className="mb-3 col-md-3">
                        <div className="actions clearfix">
                            <Button
                                onClick={()=>handleSubmission(true)}
                                style={{ float: "right" }}
                                type="button"
                                color="primary"
                                >Submit</Button>
                        </div>
                    </div>
                </Row>}
                <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    {sessionUser.role_code!="444" && 
                                    <>
                                    <th>Participant Name</th>
                                    <th>Contact</th>
                                    <th>Email</th>
                                    </>}
                                    <th scope="col">Submission</th>
                                    <th scope="col">Submission Date</th>
                                    {sessionUser.role_code=="444" && <th scope="col">File Name</th>}
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Submission ID</th> */}
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {submittedFiles && submittedFiles.map((item, i)=>{
                                    const checkIsActiveOrNot =(data)=>{
                                        return (data.fileDetails && data.fileIsActive)
                                    }
                                   
                                    return(
                                        <tr key={i}>
                                            {sessionUser.role_code!="444" && 
                                            <><td>{item['studentFullName']}</td>
                                            <td>{item['user.userDetail.academicProfile.academicContactNo']}</td>
                                            <td>{item['user.userDetail.academicProfile.academicEmail']}</td>
                                            </>}
                                            <td>Attempt No : {item.fileSequence}</td>
                                            <td>{item.createdAt}</td>
                                            {sessionUser.role_code=="444" && <td>{item.fileName}</td>}
                                            <td onClick={() => { getSubmissionId(item) }} style={{cursor:"pointer"}}>{(getMaxValue(submittedFiles,'fileSequence')==item.fileSequence && item.fileDetails) ? <span className="badge badge-soft-success font-size-12">Success</span> : item.fileIsActive ? <span className="badge badge-soft-warning font-size-12">Processing</span> : <span className="badge badge-soft-danger font-size-12">Not Active</span>}
                                            {/* <td><span className="badge badge-soft-success font-size-12">Success</span> */}
                                            </td>
                                            <td>
                                                {checkIsActiveOrNot(item)==true && <>
                                                {submittedFiles.turnitinConnectivity==true && <button onClick={()=>getCloudViewUrl(item)} className="btn btn-success btn-sm" ><img src={turnitinLogo} alt="icon" style={{ marginRight: '8px', maxHeight:'20px' }} />Similarity Report</button>}
                                                {" "}<button onClick={()=>rubricGradingHandler(item)} className="btn btn-info btn-sm" >Rubric Grading</button>
                                                </>} {" "}
                                                <a href={item.fileDownloadLink} target="_blank" className="btn btn-primary btn-sm" >View File</a>
                                            </td>
                                            {/* <td>
                                                <button onClick={()=>getCloudViewUrl(item)} className="btn btn-success btn-sm" ><img src={turnitinLogo} alt="icon" style={{ marginRight: '8px', maxHeight:'20px' }} />Similarity Report</button> {" "}
                                                <a href={item.fileDownloadLink} target="_blank" className="btn btn-primary btn-sm" >View File</a>
                                            </td> */}
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </Table>
                    </div>
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={showRubricGrading}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Grading{" "}({Object.values(rubricGrade).reduce((acc, curr) => acc + curr, 0)})</h5>
              <button
                type="button"
                onClick={()=>{setShowRubricGrading(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                
                    {rubricDetails && rubricDetails.rubricCriteria.map((item, i)=>{
                        const generateLabels = (maxPoints) => {
                            const labels = {};
                            for (let i = 0; i <= maxPoints; i += 0.5) {
                                labels[i] = i;
                            }
                            return labels;
                        };

                        return(
                                <Col md={12} className={i>0&&"pt-5"}>
                                    <h5 className="font-size-18 mb-3 mt-0">
                                    {i+1} : <span style={{fontWeight:"bold"}}>{item.description}</span>
                                    </h5>
                                    <Slider
                                        value={rubricGrade[item.id]}
                                        min={0}
                                        step={0.5}
                                        max={item.maxPoints}
                                        labels={generateLabels(item.maxPoints)}
                                        orientation="horizontal"
                                        onChange={value => {
                                            SHelper.userHasEditPermission() && onchangeRubricRangeHandler(item.id,value)
                                        }}
                                    />
                            </Col>
                        )
                    })}
                        <Col md={12} className={"pt-5"}>
                            <h5 className="font-size-14 mb-3 mt-0">
                                Remarks
                            </h5>
                            <input
                                name="courseTypeDescription"
                                value={instructorRemarks}
                                readOnly={!SHelper.userHasEditPermission()}
                                onChange={(e) => { setInstructorRemarks(e.target.value); }}
                                type="textarea"
                                className="form-control"
                            />
                        </Col>
                  
                {/* <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col" style={{minWidth:"150px"}}>Point</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rubricDetails && rubricDetails.rubricCriteria.map((item, i)=>{
                                    const generateLabels = (maxPoints) => {
                                        const labels = {};
                                        for (let i = 1; i <= maxPoints; i++) {
                                          labels[i] = i;
                                        }
                                        return labels;
                                      };

                                    return(
                                        <tr key={i}>
                                            <td>{item.description}</td>
                                            <td>
                                            <Slider
                                            value={rubricGrade[i]}
                                            min={1}
                                            max={item.maxPoints}
                                            labels={generateLabels(item.maxPoints)}
                                            orientation="horizontal"
                                            onChange={value => {
                                                onchangeRubricRangeHandler(i,value)
                                            }}
                                            />
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </Table>
                    </div> */}
            </div>
            
            <div className="modal-footer" style={{textAlign: 'right'}}>
                {SHelper.userHasEditPermission() ?
                <Button color="primary" type="button" className="btn" onClick={()=>{ submitRubricGrading(); }}>
                    Update Grading
                </Button>:
                <Button color="danger" type="button" className="btn" onClick={()=>{ setShowRubricGrading(false); }}>
                    Ok
                </Button>}
            </div>
          </Modal>
          <CustomModal modelShow={update_status_modal_center} handleCallback={deletecourseAssignment} bodyMsg={"Do you want to delete this data ?"}/>
          <CustomModal modelShow={announcement_mail_modal} handleCallback={sendAnnouncementMail} bodyMsg={"Would you like to send this assignment email to all of your students?"}/>
        </Row>
    )
}

export default Model
