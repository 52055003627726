import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [paymentCoordinatorRoleId, setPaymentCoordinatorRoleId] = useState(null)
    const [serviceCoordinatorRoleId, setServiceCoordinatorRoleId] = useState(null)
    const [courseCoordinatorRoleId, setCourseCoordinatorRoleId] = useState(null)
    const [roles, setroles] = useState([])
    const [courseDD, setCourseDD] = useState([]);
    const [serviceDD, setServiceDD] = useState([]);
    const [roleId, setRoleId] = useState(0)
    const [courseId, setCourseId] = useState([])
    const [serviceId, setServiceId] = useState([])
    const [updateData, setUpdateData] = useState(false)
    const [imageSrc, setImageSrc] = useState(mediaImage);
    const [files, setFiles] = useState([]);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        // else if (props.id && props.id > 0 && imageSrc == mediaImage) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        // else if (!props.id && files.length == 0) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    id:updateData.id,
                    userFirstName:values['userFirstName'],
                    userLastName:values['userLastName'],
                    userEmail:values['userEmail'],
                    userContactNo:values['userContactNo'],
                    userAddress:values['userAddress'],
                    username:values.username,
                    userDetailId:values.userDetailId,
                    roleId:roleId,
                    courseId: roleId==paymentCoordinatorRoleId || roleId==courseCoordinatorRoleId ? courseId.map(item => item.value) : [],
                    serviceId: roleId==serviceCoordinatorRoleId || roleId==courseCoordinatorRoleId ? serviceId.map(item => item.value) : []
                }

                await Axios.patch(`/user/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {
                            await Axios.patch(`/user/details/id/${updateData.userDetailId}`, jsonData)
                            .then(async (response2) => {
                                if (response2.data.status === 200) {
                                    toast.success('User Details updated successfully!', {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                    props.handleCallback(response2.data)
                                } else {
                                    toast.error(response2.data.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                                }
                            })
                            .catch((e) => {
                                toast.error(e, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                            })
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    userFirstName:values['userFirstName'],
                    userLastName:values['userLastName'],
                    userEmail:values['userEmail'],
                    userContactNo:values['userContactNo'],
                    userAddress:values['userAddress'],
                    username:values.username,
                    branchId:1,
                    roleId:roleId,
                    courseId: roleId==paymentCoordinatorRoleId || roleId==courseCoordinatorRoleId ? courseId.map(item => item.value) : [],
                    serviceId: roleId==serviceCoordinatorRoleId || roleId==courseCoordinatorRoleId ? serviceId.map(item => item.value) : []
                }

                await Axios.post("/user", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            toast.success('User created successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            setTimeout(() => {
                                history.push("/user-list");
                            }, 1000);

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }
    useEffect(async () => {
        await Axios.get("/user/role/dropdown")
        .then((response) => { 
            if(response.data.status===200){
                setroles(response.data.data);
                setPaymentCoordinatorRoleId(response.data.paymentCoordinatorRoleId);
                setServiceCoordinatorRoleId(response.data.serviceCoordinatorRoleId);
                setCourseCoordinatorRoleId(response.data.courseCoordinatorRoleId);
            }
            else{
                setroles([])
            }
        }).catch(e=>{
            setroles([])
        });

        await Axios.get("/course/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    let courseDD = response.data.data;
                    let allCourse = {label:"All Course", value:"-1"};
                    setCourseDD([allCourse,...courseDD]);
                }
            }
        );

        await Axios.get("/service/dropdown")
            .then((response) => {
                if (response.data.status === 200) {
                    let dd = response.data.data;
                    let allCourse = {label:"All Service", value:"-1"};
                    setServiceDD([allCourse,...dd]);
                }
            }
        );

        if (props.id && props.id > 0) {
            await Axios.get(`/user/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        console.log(response)
                        setRoleId(response.data.data.userDetail.role.id);
                        setUpdateData(response.data.data);
                        if(response.data.data.userAccesses){
                            
                            let selectedCourses = [], selectedServices = [];
                            response.data.data.userAccesses.forEach(element => {
                                if(element.haveAllCourseAccess){
                                    selectedCourses.push({label:"All Course", value:"-1"});
                                }
                                else if(element.haveAllServiceAccess){
                                    selectedServices.push({label:"All Service", value:"-1"});
                                }
                                else{
                                    if(element.course) selectedCourses.push(element.course);
                                    if(element.service) selectedServices.push(element.service);
                                }
                                
                            });
                            setCourseId(selectedCourses);
                            setServiceId(selectedServices);
                        }
                    }
                })
        }
        
        return () => {
            // Cancel any outstanding requests or subscriptions here
        };
    }, [props.id]);


    return (
        <Row>
        {(!props.id || props.id===0 || (props.id>0 && updateData) ) &&
        <Col xl="12">
            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                    <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom01">First Name</Label>
                            <AvField
                                name="userFirstName"
                                defaultValue={updateData.userDetail?.userFirstName}
                                placeholder="First Name"
                                type="text"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                errorMessage="Please provide a first name."
                                id="validationCustom01"
                            />
                        </div>
                        </Col>
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom02">Last Name</Label>
                            <AvField
                            name="userLastName"
                            placeholder="Last Name"
                            defaultValue={updateData.userDetail?.userLastName}
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage=" Please provide last name."
                            id="validationCustom02"
                            />
                        </div>
                        </Col>
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom03">Role</Label>
                            <Select
                                options={roles}
                                value={roles.filter(x=>x.value==roleId)[0]}
                                onChange={(e)=>{setRoleId(e.value);}}
                                name="roleId"
                            />
                        </div>
                        </Col>
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom04">Username</Label>
                            <AvField
                            name="username"
                            defaultValue={updateData.username}
                            placeholder="Username"
                            type="text"
                            validate={{ required: { value: true } }}
                            errorMessage=" Please provide username."
                            className="form-control"
                            id="validationCustom04"
                            />
                        </div>
                        </Col>
                        {(roleId==paymentCoordinatorRoleId || roleId==courseCoordinatorRoleId) &&<>
                            <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="courseId">Permitted Monitoring Courses</Label>
                                <Select
                                    isMulti={true}
                                    options={courseDD}
                                    value={courseId}
                                    onChange={(e)=>{setCourseId(e);}}
                                    name="courseId"
                                    id="courseId"
                                />
                            </div>
                            </Col>
                        </>}
                        {(roleId==serviceCoordinatorRoleId || roleId==courseCoordinatorRoleId) &&<>
                            <Col md="12">
                            <div className="mb-3">
                                <Label htmlFor="serviceId">Permitted Monitoring Service</Label>
                                <Select
                                    isMulti={true}
                                    options={serviceDD}
                                    value={serviceId}
                                    onChange={(e)=>{setServiceId(e);}}
                                    name="serviceId"
                                    id="serviceId"
                                />
                            </div>
                            </Col>
                        </>}
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom05">Contact No</Label>
                            <AvField
                            name="userContactNo"
                            defaultValue={updateData.userDetail?.userContactNo}
                            placeholder="Contact No"
                            type="text"
                            validate={{ required: { value: true } }}
                            errorMessage=" Please provide contact no."
                            className="form-control"
                            id="validationCustom05"
                            />
                        </div>
                        </Col>
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom06">Email</Label>
                            <AvField
                            name="userEmail"
                            defaultValue={updateData.userDetail?.userEmail}
                            placeholder="Email"
                            type="email"
                            validate={{ required: { value: true } }}
                            errorMessage=" Please provide email."
                            className="form-control"
                            id="validationCustom06"
                            />
                        </div>
                        </Col>
                        <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="validationCustom07">Address</Label>
                            <AvField
                            name="userAddress"
                            defaultValue={updateData.userDetail?.userAddress}
                            placeholder="Address"
                            type="text"
                            validate={{ required: { value: true } }}
                            errorMessage=" Please provide address."
                            className="form-control"
                            id="validationCustom07"
                            />
                        </div>
                        </Col>
                    </Row>
                    <Col style={{textAlign: 'right'}}>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>
        }
    </Row>
    )
}

export default Model
