import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Modal,Nav,Label,
  NavItem,NavLink,Button as RButton } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableLoader from "../../components/Common/TableLoader"
import Axios from "../../helpers/axios_helper"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
import { List,Delete,Edit,ChangeCircle, Download, Mail } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import AcademicProfileModal from "./model";
import CustomModal from "../Common/CustomModal";
import { ExportToCsv } from 'export-to-csv';
import * as sessionHelper from '../../helpers/session_helper';

const statusList = [
  { label: "Waiting", value: 1 },
  { label: "Screening", value: 2 },
  { label: "Active", value: 3 },
  { label: "Rejected", value: 4 }
]

const DatatableTables = () => {

  const [data, setData] = useState([]);
  const [userHasEditPermission, setUserHasEditPermission] = useState(false);
  const [paymentCoUser, setPaymentCoUser] = useState(false);
  const [serviceCoUser, setServiceCoUser] = useState(false);
  const [courseCoUser, setCourseCoUser] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0)

  const selectedItem = useRef(0)
  const selectedItemCourse = useRef(0)
  const [modal_center, setmodal_center] = useState(false)
  const [delete_status_modal_center, setDelete_status_modal_center] = useState(false);
  const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);
  const [asking_payment_modal_center, setAsking_payment_modal_center] = useState(false);
  const [selected_rows, setSelected_rows] = useState([]);
  const [status, setStatus] = useState(null);

  const [courseDD, setCourseDD] = useState([]);
  const [courseId, setCourseId] = useState([]);
  const [serviceDD, setServiceDD] = useState([]);
  const [serviceId, setServiceId] = useState([]);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    academicFullName: 'contains',
    academicLastName: 'contains',
    academicContactNo: 'contains',
    academicEmail: 'contains',
    'enrolledCourseOrServices.course.courseName': 'contains',
    'enrolledCourseOrServices.course.courseSession': 'contains',
    'enrolledCourseOrServices.createdAt': 'contains'
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const [columnVisibility, setColumnVisibility] = useState({
    'enrolledCourseOrServices.id': false, // Initially hidden
    'enrolledCourseOrServices.course.id': false
  });
  //#region Datatable

  const columns = useMemo(() => {
    const userHasEditPermission = true; // Example condition for logic
  
    const baseColumns = [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        size:50,
        accessorKey: 'participantId',
        header: "Participant ID",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        size:50,
        accessorKey: 'learnerId',
        header: "Learner ID",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()??'-'}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'academicFullName',
        header: "Full name",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'academicContactNo',
        header: "Contact",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'academicEmail',
        header: "Email",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'enrolledCourseOrServices.course.courseName',
        header: "Course Name",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['enrolledCourseOrServices.course.courseName']??"-"}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'enrolledCourseOrServices.course.courseSession',
        header: "Course Session",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['enrolledCourseOrServices.course.courseSession']??"-"}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'enrolledCourseOrServices.campus',
        header: "Campus",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['enrolledCourseOrServices.campus']??"-"}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'enrolledCourseOrServices.course.courseCode',
        header: "Course Code",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['enrolledCourseOrServices.course.courseCode']??"-"}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'enrolledCourseOrServices.entryDate',
        header: "Entry Date",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['enrolledCourseOrServices.entryDate']??"-"}</Typography>
        ),
        enableColumnFilterModes: false
      },
      {
        accessorKey: 'enrolledCourseOrServices.id', // Key for the hidden column
        header: "EnrolledCourseOrServices ID (Hidden)",
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        isVisible: false,
      },
      {
        accessorKey: 'enrolledCourseOrServices.course.id', // Key for the hidden column
        header: "EnrolledCourse ID (Hidden)",
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        isVisible: false,
      },
    ];
  
    // Conditionally add the academicIsActive column
    // // if (activeTab==0) {
    // //   baseColumns.push({
    // //     accessorKey: "academicIsActive",
    // //     header: "Is Active",
    // //     Cell: ({ cell, row }) => (
    // //       <Col style={{minWidth:"100px"}}>
    // //       <Typography variant='h7' id={`academicIsActive${row.original.id}`}>{cell.getValue()==1?"Yes":"No"}</Typography>
    // //         {userHasEditPermission && <Tooltip arrow placement="left" title="Change">
    // //         <IconButton onClick={() => {
    // //           selectedItem.current=row.original.id; setUpdate_status_modal_center(true)
    // //           }}
    // //           >
    // //           <ChangeCircle />
    // //         </IconButton>
    // //       </Tooltip>}
    // //       </Col>
    // //     ),
    // //     enableColumnFilterModes: false,
    // //     enableColumnActions: false,
    // //     enableSorting: false,
    // //   });
    // }
  
    return baseColumns;
  }, []);

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  const fetchDataFromAPI = async (reset = false, allShow = false) => {
    if(courseId.length==0 && !allShow){
      return;
    }

    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    let confirmedTransaction = ''
    if(activeTab==0){
      confirmedTransaction=`${0}/`
    }
    else if(activeTab==1){
      confirmedTransaction=`${4}/`
    }

    let url = `/academicProfile/list/${activeTab}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    
    if(paymentCoUser || serviceCoUser || userHasEditPermission || courseCoUser){
      let selectedCourse = courseId ? courseId.map(item => item.value) : [];

      if(!selectedCourse.includes('-1')){
        url = `/academicProfile/list/byCourse/${activeTab}&${selectedCourse.join('_')}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
      }
    }

    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          // response.data?.data.forEach(element => {
          //   element.participantId=`${new Date(element.createdAt).getMonth()+element['id']}-${new Date(element.createdAt).getFullYear()+element['id']+new Date(element.createdAt).getMonth()+element['id']}`
          // });
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  const customSearch = (allShow) =>{
    if(paymentCoUser || userHasEditPermission || courseCoUser){
      if(courseId.length>0){
        fetchDataFromAPI(true);
      }
      else{
        toast.error("Please select course then try to search !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          transition: Slide,
        });
        return;
      }
    }
    if(serviceCoUser || courseCoUser){
      if(courseId.length>0){
        fetchDataFromAPI(true);
      }
      else{
        toast.error("Please select service then try to search !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          transition: Slide,
        });
        return;
      }
    }
    if(allShow){
      fetchDataFromAPI(true,allShow);
    }
    
  }

  useEffect(() => {
    fetchDataFromAPI(true);
  }, [
    activeTab
  ]);

  useEffect(async () => {
    if(sessionHelper.userHasEditPermission()){
      setUserHasEditPermission(true);
      await Axios.get('/open/course/dropdown')
        .then(function (response) {
          if (response && response.data.status==200) {
            let courseDD = response.data.data;
            let allCourse = {label:"All Course", value:"-1"};
            setCourseDD([allCourse,...courseDD]);
            setCourseId([allCourse]);
            customSearch(true);
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
    }
    else{
      if(sessionHelper.userIsPaymentCO()){
        setPaymentCoUser(true);
      }
      if(sessionHelper.userIsServiceCO()){
        setServiceCoUser(true);
      }
      if(sessionHelper.userIsCourseCO()){
        setCourseCoUser(true);
      }
      await Axios.get('/user/access/dropdowns')
      .then(function (response) {
        if (response && response.data.status==200) {
          setCourseDD(response.data.courseDD);
          setServiceDD(response.data.serviceDD);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
    }
  }, []);

  //#endregion

  //#region Modal

  const handleCallback = () =>{
    selectedItem.current=0;
    setmodal_center(false);
    // loadList();
  }

  const deleteModalCallbackStatus = async (result) =>{
    if(result){
      deleteAcademicProfile(selectedItem.current);
    }
    setDelete_status_modal_center(false);
    selectedItem.current=0;
  }

  const askingPaymentModalCallbackStatus = async (result) =>{
    if(result){
      askingPaymentMail(selectedItem.current,selectedItemCourse.current);
    }
    setAsking_payment_modal_center(false);
    selectedItem.current=0;
    selectedItemCourse.current=0;
  }

  const deleteAcademicProfile = async (id) =>{
    window.sLoader();
    const url = `/academicProfile/id/${id}`;
    await Axios.delete(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          // document.getElementById(`academicIsActive${id}`).innerHTML=response.data.academicIsActive==1?"True":"False"
          fetchDataFromAPI(true);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  }

  const askingPaymentMail = async (id, courseId) =>{
    window.sLoader();
    const successUrl = JSON.stringify(
      {
          academicProfileId:id,
          courseId:courseId,
          activeTab:6,
      }
    )

    const url = `/academicProfile/sendAskingPaymentMail/${id}&${courseId}`;
    await Axios.post(url)
      .then(function (response) {
        if (response && response.data?.status==200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.data?.message ?? "Sending Mail Failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  }


  const updateModalCallbackStatus = async (result) =>{
    
    if(result){
      if(!status){
        toast.error("Please select Status !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          transition: Slide,
        });
      }
      else{
        let ids = []
        selected_rows.forEach((row) => {
          ids.push(row.original['enrolledCourseOrServices.id']);
        });

        updateActiveStatus(ids);
        
        
      }
    }
    setUpdate_status_modal_center(false);
    selectedItem.current=0;
  }

  const updateActiveStatus = async (selectedIds) =>{
    window.sLoader();
    const url = `/academicProfile/changeStatus/${status}`;
    await Axios.patch(url,selectedIds)
      .then(function (response) {
        if (response && response.data?.status==200) {
          // document.getElementById(`academicIsActive${id}`).innerHTML=response.data.academicIsActive==1?"True":"False"
          fetchDataFromAPI(true);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
      window.hLoader();
  }

  const csvOptions = {
    filename:'Participants List',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const csvData = rows.map((row) => {
      const processedRow = {};
      columns.forEach((column) => {
          // Use column.header as the key and row.original[column.accessorKey] as the value
          processedRow[column.header] = column.accessorKey
              ? row.original[column.accessorKey]
              : '-';
      });
      return processedRow;
  });
    csvExporter.generateCsv(csvData);
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination({
      ...pagination,
      pageIndex: 0, // Reset to the first page when pageSize changes
      pageSize: newPageSize==-1?9999999:newPageSize,
    });
  };
  //#endregion
  
  return (
    <>
      <div className="page-content">

        <Breadcrumbs title="Academic" breadcrumbItem="List of Participants" />
        <Row>
          <AvForm className="needs-validation" onSubmit={()=>{}} style={{zIndex:"99"}}>
            <Row>
              <Col md={(paymentCoUser || userHasEditPermission) ? 10 : serviceCoUser ? 10 : 10}>
                <Row className="mb-3">
                  <label className="col-md-2 col-form-label">Course</label>
                  <div className="col-md-10">
                    <Select
                      name={"asdas"}
                      isMulti={true}
                      id="presentDivision"
                      options={courseDD}
                      value={courseId}
                      onChange={(e)=>{setCourseId(e);}}>
                    </Select>
                  </div>
                </Row>
              </Col>
              {/* {serviceCoUser || courseCoUser && 
              <Col md={5}>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">Service</label>
                  <div className="col-md-8">
                    <Select
                      name={"asdas"}
                      id="presentDivision"
                      options={serviceDD}
                      isMulti={true}
                      onChange={(e)=>{setServiceId(e);}}>

                    </Select>
                  </div>
                </Row>
              </Col>} */}
              <Col md={2} style={{textAlign:"right"}}>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={()=>{customSearch()}}
              >
                <i className="mdi mdi-database-search font-size-16 align-middle me-2"></i>{" "}
                  Search
                </button>{" "}
              </Col>
            </Row>
          </AvForm>
          <Col className="col-12">
            <Card>
            <CardBody>
              
              <br/>
              <Nav tabs>
              <NavItem>
                <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 0,
                    })}
                    onClick={() => {
                      setActiveTab(0)
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Payment Pending</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 1,
                    })}
                    onClick={() => {
                      setActiveTab(1)
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Awaiting</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 2,
                    })}
                    onClick={() => {
                      setActiveTab(2)
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Screening</span>
                  </NavLink>
                </NavItem> 
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 3,
                    })}
                    onClick={() => {
                      setActiveTab(3)
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Active</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === 4,
                    })}
                    onClick={() => {
                      setActiveTab(4)
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Rejected</span>
                  </NavLink>
                </NavItem>
              </Nav>

                {true ?
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enableGlobalFilter={false}
                    enableColumnFilterModes
                    initialState={{
                      showColumnFilters: false
                    }}
                    manualFiltering
                    manualPagination
                    manualSorting
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    tableInstanceRef={tableInstanceRef}
                    rowCount={rowCount}
                    onColumnVisibilityChange={setColumnVisibility}
                    enableEditing
                    state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                      columnVisibility
                    }}
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Action',
                      },
                    }}

                    renderRowActions={({ row, table }) => (
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip arrow placement="left" title={userHasEditPermission ? "Edit" : "View"}>
                          <IconButton onClick={() => {selectedItem.current=row.original.id;  setmodal_center(true);}}>
                            { userHasEditPermission ? <Edit /> : <List />}
                          </IconButton>
                        </Tooltip>
                        {userHasEditPermission &&
                        <Tooltip arrow placement="left" title="Delete">
                          <IconButton style={{color:"red"}} onClick={() => {selectedItem.current=row.original.id; setDelete_status_modal_center(true)}}>
                            <Delete />
                          </IconButton>
                        </Tooltip>}
                        {activeTab==0 &&
                        <Tooltip arrow placement="left" title="Payment Mail">
                          <IconButton style={{color:"blue"}} onClick={() => {selectedItem.current=row.original.id; setAsking_payment_modal_center(true); selectedItemCourse.current=row.original['enrolledCourseOrServices.course.id'];}}>
                            <Mail />
                          </IconButton>
                        </Tooltip>}
                      </Box>
                    )}
                    enableRowSelection={true}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 25, 50, 100, { label: 'All', value: -1 }],
                      onRowsPerPageChange: (event) => {
                        handlePageSizeChange(Number(event.target.value));
                      },
                      showFirstButton: true,
                      showLastButton: true,
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                      >
                        <Button
                          disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() => {setStatus(activeTab); setSelected_rows(table.getSelectedRowModel().rows); setUpdate_status_modal_center(true);}}
                          startIcon={<ChangeCircle />}
                          variant="contained"
                        >
                          Change Status
                        </Button>
                        <Button
                          disabled={table.getRowModel().rows.length === 0}
                          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                          onClick={() => handleExportRows(table.getRowModel().rows)}
                          startIcon={<Download />}
                          variant="contained"
                        >
                          Export Page Rows
                        </Button>
                        <Button
                          disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                          startIcon={<Download />}
                          variant="contained"
                        >
                          Export Selected Rows
                        </Button>
                      </Box>
                    )}
                  />
                  :
                  <TableLoader />
                }
              </CardBody>
            </Card>
          </Col>
          <Modal
            size="xl"
            isOpen={modal_center}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">{userHasEditPermission ? "Update Participant" : "Participant Details"}</h5>
              <button
                type="button"
                onClick={handleCallback}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
              <AcademicProfileModal id={selectedItem.current} courseId={selectedItemCourse.current} handleCallback={handleCallback} viewOnly={!userHasEditPermission}/>
            </div>
          </Modal>

          <CustomModal modelShow={delete_status_modal_center} handleCallback={deleteModalCallbackStatus} bodyMsg={"Do you want to delete this data ?"}/>
          <CustomModal modelShow={asking_payment_modal_center} handleCallback={askingPaymentModalCallbackStatus} bodyMsg={"Do you want to send payment mail ?"}/>
          {/* <CustomModal modelShow={update_status_modal_center} handleCallback={updateModalCallbackStatus} bodyMsg={"Do you want to update participant status."}/> */}
          <Modal
            size="sm"
            isOpen={update_status_modal_center}
            centered={true}>
              
            <div className="modal-header">
              <h5 className="modal-title mt-0">{"Status Update !"} </h5>
              <button
                type="button"
                onClick={() => {
                  setUpdate_status_modal_center(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
                <CardBody>
                    <Row>
                      <Col md="12">
                          <div className="mb-3">
                              {/* <Label>Status</Label> */}
                                <Select
                                  value={status ? statusList.find(option => option.value == status) : null}
                                  isMulti={false}
                                  onChange={(data) => {
                                      setStatus(data.value);
                                  }}
                                  options={activeTab==0?statusList.filter(x=>x.value==4):statusList}
                                  classNamePrefix="select2-selection"
                                  placeholder={"Select status"}
                                />
                          </div>
                      </Col>
                    </Row>
                </CardBody>
            </div>
            <div className="modal-footer customBtnArea" style={{textAlign: 'right'}}>
                <RButton color="primary" type="submit" className="btn btn-sm" onClick={()=>updateModalCallbackStatus(true)}>
                    Update
                </RButton>
                <RButton color="danger" type="submit" className="btn btn-sm" onClick={()=>updateModalCallbackStatus(false)}>
                    Cancel
                </RButton>
            </div>
          </Modal>
        </Row>
      </div>

    </>
  )
}

export default DatatableTables
